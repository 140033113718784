import { defineStore } from "pinia";
import { ref, onMounted, computed } from "vue";

export const useSupportedBrowserStore = defineStore("supportedBrowser", () => {
  const device = useDevice();
  const modalClosedCookie = useCookie("unsupported-browser-modal-closed", {
    maxAge: 60 * 60 * 24,
  });

  const forceShowModal = ref(false);

  const status = ref<"INITIAL" | "LOADING" | "SUPPORTED" | "NOT_SUPPORTED">(
    "INITIAL",
  );

  const handleModalClose = () => {
    forceShowModal.value = false;
    modalClosedCookie.value = "CLOSED";
  };

  const handleModalForceOpen = () => {
    forceShowModal.value = true;
  };

  const showModal = computed(() => {
    return (
      (status.value === "NOT_SUPPORTED" && !modalClosedCookie.value) ||
      forceShowModal.value
    );
  });

  onMounted(() => {
    status.value = "LOADING";
    const isWebRTCSupported = process.client && !!window.RTCPeerConnection;
    const isBrowserSupported =
      device.isChrome ||
      device.isEdge ||
      device.isFirefox ||
      device.isSafari ||
      device.userAgent.includes("OPR/");

    if (isWebRTCSupported && isBrowserSupported) {
      status.value = "SUPPORTED";
    } else {
      status.value = "NOT_SUPPORTED";
    }
  });

  return { status, showModal, handleModalClose, handleModalForceOpen };
});
